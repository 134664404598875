<template>
  <div
    class="container mx-auto mt-20 max-h-full  md:mt-28 flex flex-wrap align-middle"
  >
    <h1
      class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800 dark:text-white"
    >
      Lei de Acesso à Informação
    </h1>

    <div class="w-full px-3 mb-4">
      <img
        src="./../../assets/img/undraw_pending_approval_xuu9.svg"
        class="inline-block w-56 h-56"
      />
    </div>
    <div class="w-full px-3">
      <h3 class="indent-sm text-justify text-xl leading-10">
        LAI é a sigla usada para Lei de Acesso à Informação. Muito usada por
        jornalistas, ela regulamenta o direito constitucional assegurado pelo
        inciso XIV, permitindo o acesso às informações públicas. Ela entrou em
        vigor no dia 16 de maio de 2012, na Lei 12.527/2011, e estipulou os
        mecanismos que possibilitam a qualquer pessoa, seja jurídica ou física,
        o resgate de dados públicos sem a necessidade de apresentar motivos e
        dar explicações aos órgãos do Estado.
      </h3>
      <a-button
        type="primary"
        style="float: right"
        shape="round"
        class="text-xl"
        @click="lerLai()"
      >
        Saiba mais
      </a-button>
    </div>
  </div>
</template>

<script>
// import { getCurrentInstance } from "vue";

export default {
  setup() {
    // const { ctx } = getCurrentInstance();

    const lerLai = () => {
      window.open(
        'http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm',
        '_blank'
      )
    }

    return {
      lerLai,
    }
  },
}
</script>
