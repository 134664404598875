<template>
  <div class="container mx-auto pt-24 md:pt-28 bg-white dark:bg-black">
    <nav class="pr-3 pl-10 md:pl-2 rounded font-sans w-full mt-10 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <router-link
          :to="PUBLIC_PATH + 'transparencia'"
          class="no-underline text-blue dark:text-white font-bold"
          >Transparência</router-link
        >
      </ol>
    </nav>
    <p class="font-medium text-2xl text-black dark:text-white my-10">
      {{ dataParams.slug }}
    </p>

    <div class="mt-10 mx-5 md:mx-0">
      <div v-if="!loading && data" class="flex flex-wrap">
        <div class="w-full lg:w-1/4 px-3 mb-4">
          <Select
            class="w-full"
            v-model:value="form.subgrupo"
            placeholder="Selecionar modalidade"
          >
            <Option value="null">TODAS</Option>
            <Option v-for="(item, idx) in data" :value="item.id" :key="idx">
              {{ item.titulo }}
            </Option>
          </Select>
        </div>
        <div class="w-full lg:w-1/4 px-3 mb-4">
          <!-- <Input
            class="w-full"
            v-model:value="ano"
            placeholder="Ano"
          ></Input> -->
          <InputNumber
            class="w-full"
            placeholder="Ano"
            v-model:value="ano"
            :max="3000"
          />
        </div>
        <div class="w-full lg:w-1/4 px-3 mb-4">
          <Input
            class="w-full"
            v-model:value="objeto"
            placeholder="Objeto"
          ></Input>
        </div>
        <div class="w-full lg:w-1/4 px-3 mb-4">
          <Button type="primary" class="w-full" @click="fetchArquivos()"
            >Pesquisar</Button
          >
        </div>
      </div>
    </div>

    <div v-if="loading" class="center-content">
      <Spin tip="Carregando..."> </Spin>
    </div>
    <p v-if="error"></p>

    <div
      v-if="loadingFiles"
      class="items-center content-center justify-center container mx-auto"
    >
      <img
        :src="
          getRefValue('baseUrl') +
            '/assets/animations/animation_300_kl2mwhra.gif'
        "
        style="display: inline-block"
      />
    </div>

    <div
      v-if="!loadingFiles && !loading && files == null"
      class="content-center"
      style="margin-top: 30vh"
    >
      <Empty>
        <template #description>
          <span>Faça uma pesquisa</span>
        </template>
      </Empty>
    </div>
    <Table
      v-if="!loadingFiles && files != null"
      :columns="columns"
      :data-source="files"
      class="mx-5 md:mx-0 overflow-x-auto"
    >
      <template #data="{text}">
        {{ dateToLocalDate(text) }}
      </template>
      <template #titulo="{ record }">
        <p>{{ record[0].titulo.toUpperCase() }}</p>
      </template>
      <template #descricao="{ record }">
        <p v-if="!record[0].subgrupo_detalhado">
          {{ getFileDescription(record[0]) }}
        </p>
        <div v-else v-html="record[0].descricao"></div>
      </template>
      <!-- <template #expandedRowRender="{ record }">
        <p v-if="!record[0].subgrupo_detalhado">
          {{ getFileDescription(record[0]) }}
        </p>
        <div v-else v-html="record[0].descricao"></div>
      </template> -->
      <template #record="{ record }">
        <div class="flex flex-row">
          <div v-for="(item, idx) in record" :key="'a-' + idx" class="">
            <a
              @click="openLink(item.url)"
              class="inline-block mx-2"
              v-if="item.url"
              href="#"
            >
              <Tooltip
                title="DOC"
                class="flex"
                placement="topLeft"
                v-if="item.extensao.toUpperCase() == 'DOC'"
              >
                <FileWordOutlined style="font-size: 25px" />
              </Tooltip>
              <Tooltip
                title="PDF"
                placement="topLeft"
                class="flex"
                v-if="item.extensao.toUpperCase() == 'PDF'"
              >
                <FilePdfOutlined style="font-size: 25px; color: red" />
              </Tooltip>
              <Tooltip
                title="TXT"
                class="flex"
                placement="topLeft"
                v-if="item.extensao.toUpperCase() == 'TXT'"
              >
                <FileTextOutlined style="font-size: 25px; color: green" />
              </Tooltip>
            </a>
          </div>
          <div
            v-for="(item, idx) in 3 - record.length"
            :key="'b-' + idx"
            class="flex"
          >
            <p class="inline-block mx-2">
              <FileTextOutlined style="font-size: 25px; color: transparent" />
            </p>
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import {
  Spin,
  Input,
  InputNumber,
  Select,
  Button,
  Empty,
  Table,
  Tooltip,
} from 'ant-design-vue'
import { ref, onMounted } from 'vue'
import API from './../../api/api.js'
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import {
  FilePdfOutlined,
  FileWordOutlined,
  FileTextOutlined,
} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import useSettings from './../../store/settings'
import moment from 'moment'

export default {
  components: {
    FilePdfOutlined,
    FileWordOutlined,
    FileTextOutlined,
    Option: Select.Option,
    Spin,
    Input,
    InputNumber,
    Tooltip,
    Select,
    Button,
    Empty,
    Table,
    moment,
  },
  setup() {
    const route = useRoute()

    const data = ref(null)
    const files = ref(null)
    const loading = ref(true)
    const loadingFiles = ref(false)
    const error = ref(null)

    let form = { grupo: null, subgrupo: null, ano: '', objeto: '' }
    let objeto = ref('')
    let ano = ref('')

    const { getRefValue, PARAMS } = useSettings()

    let dataParams = { slug: null }

    const fetchModalidades = async () => {
      loading.value = true
      try {
        const res = await API.get(
          `modalidade/entidade/${ENTIDADE}/${form.grupo}`
        )
        if (res.status != 200) {
          const error = new Error(res.status)
          error.value.message = 'Ocorreu um erro'
          throw error
        } else {
          data.value = res.data
        }
      } catch (e) {
        const error = new Error(500)
        error.value.message =
          'Ocorreu um erro interno, tente novamente mais tarde'
        throw error
      } finally {
        loading.value = false
      }
    }

    const isFullLink = (link) => {
      if (link == null || link == undefined) return false
      return (
        link.includes('http://') ||
        link.includes('https://') ||
        link.includes('www.')
      )
    }

    const openLink = (link) => {
      if (!isFullLink(link)) {
        link = getRefValue('urlBaseFiles') + link
      }
      console.log(link)
      window.open(link, '_blank')
    }

    const getFileDescription = (file) => {
      const date = new Date(file.data_publicacao)
      let descricao =
        file.descricao == null || file.descricao == undefined
          ? file.subgrupo_descricao
          : file.descricao
      if (descricao == null || descricao == undefined) return ''
      descricao = descricao.toUpperCase()
      descricao = descricao.replace('[ANO]', date.getFullYear())
      descricao = descricao.replace('[MES]', date.getMonth())
      descricao = descricao.replace('[DIA]', date.getDay())
      return descricao
    }

    const fetchArquivos = async () => {
      const findInList = (x, filesGroup) => {
        for (let group of filesGroup) {
          if (group.length >= 3) {
            if (x == group[0].id) return true
            if (x == group[1].id) return true
            if (x == group[2].id) return true
          } else if (group.length == 2) {
            if (x == group[0].id) return true
            if (x == group[1].id) return true
          } else {
            if (x == group[0].id) return true
          }
        }
        return false
      }
      try {
        loadingFiles.value = true
        let formData = {
          ...form,
          ano: ano.value,
          objeto: objeto.value,
          autarquia: ENTIDADE,
        }
        if (formData.ano == null || formData.ano.length < 4) delete formData.ano
        if (formData.objeto == null || formData.objeto == '')
          delete formData.objeto
        if (
          formData.subgrupo == null ||
          formData.subgrupo == 'null' ||
          formData.subgrupo == ''
        )
          delete formData.subgrupo
        const res = await API.post(`arquivo/entidade/${ENTIDADE}`, formData)
        if (res.status != 200) {
          throw 'Ocorreu um erro ao buscar os arquivos'
        } else {
          const filesGroup = []
          for (let file of res.data) {
            if (!findInList(file.id, filesGroup)) {
              const group = res.data.filter((x) => x.titulo == file.titulo)
              filesGroup.push(group)
            }
          }

          files.value = filesGroup
        }
        loadingFiles.value = false
      } catch (e) {
        throw 'Ocorreu um erro ao buscar os arquivos'
      } finally {
        loadingFiles.value = false
      }
    }

    const dateToLocalDate = (date) => {
      return moment(date).format('DD/MM/YYYY')
    }

    const columns = [
      {
        title: 'Documento',
        dataIndex: 'titulo',
        key: 'titulo',
        slots: { customRender: 'titulo' },
      },
      {
        title: 'Data de Publicação',
        dataIndex: 'data_publicacao',
        key: 'data_publicacao',
        slots: { customRender: 'data' },
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        key: 'descricao',
        slots: { customRender: 'descricao' },
      },
      {
        title: '',
        dataIndex: 'url',
        key: 'url',
        slots: { customRender: 'record' },
        align: 'right',
        width: '100px',
      },
    ]

    // const slug = ctx.$route.params.slug;

    onMounted(async () => {
      form.grupo = route.params.id
      dataParams.slug = route.params.slug
      await fetchModalidades()
      await fetchArquivos()
    })

    const fileExt = (url, ext) => {
      let extFile = url.split('.').pop()
      extFile = extFile.toUpperCase()
      if (extFile == ext) return true
      return false
    }

    return {
      fileExt,
      // fetchModalidades,
      isFullLink,
      columns,
      form,
      data,
      loading,
      loadingFiles,
      error,
      files,
      fetchArquivos,
      openLink,
      dataParams,
      ano,
      objeto,
      getRefValue,
      dateToLocalDate,
      getFileDescription,
      PUBLIC_PATH,
      // slug,
    }
  },
}
</script>

<style></style>
