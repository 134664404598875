<template>
  <div
    class="mx-auto container  pt-24 md:pt-28 arquivos mb-10 w-full bg-white dark:bg-black"
  >
    <div class="flex flex-wrap w-full">
      <!-- Posts -->
      <div class="w-full md:w-9/12 mx-auto px-10">
        <div class="flex items-center mt-5 justify-between mb-10">
          <h1
            class="text-xl font-bold text-gray-700 dark:text-white md:text-2xl"
          >
            {{
              isSearchResults
                ? `Resultado(s) da busca por: ${
                    form.key != '' ? form.key : 'Todas as publicações'
                  }  ${form.year != '' ? ' | Ano: ' + form.year : ''}`
                : 'Postagens recentes'
            }}
          </h1>
        </div>
        <div class="flex flex-wrap -m-4" v-if="loading != false">
          <ShimmerCard
            v-for="(item, idx) in 9"
            :key="idx"
            width="w-1/3 w-full"
          />
        </div>

        <div v-if="data && !loading" class="flex flex-wrap -m-4 ">
          <CardPost :post="item" v-for="(item, idx) in data" :key="idx" />
        </div>
      </div>

      <!-- Sidebar -->
      <div class="w-full md:w-3/12 mx-auto px-10 ">
        <div class="relative w-full mt-24">
          <div
            class="flex flex-wrap justify-between bg-gray-200 dark:bg-white  shadow rounded border-0"
          >
            <input
              v-model="form.key"
              type="search"
              class=" inlin p-3  bg-gray-200 dark:bg-white  w-10/12 outline-none"
              placeholder="Pesquise por título ou conteúdo"
            />
            <div
              @click="search"
              class="h-max align-middle rounded-md cursor-pointer w-2/12 text-purple-lighter bg-gray-200  pl-2 dark:bg-white"
            >
              <svg
                version="1.1"
                class="h-4 text-dark  align-middle mt-3"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 52.966 52.966"
                style="enable-background:new 0 0 52.966 52.966;"
                xml:space="preserve"
              >
                <path
                  d="M51.704,51.273L36.845,35.82c3.79-3.801,6.138-9.041,6.138-14.82c0-11.58-9.42-21-21-21s-21,9.42-21,21s9.42,21,21,21
        c5.083,0,9.748-1.817,13.384-4.832l14.895,15.491c0.196,0.205,0.458,0.307,0.721,0.307c0.25,0,0.499-0.093,0.693-0.279
        C52.074,52.304,52.086,51.671,51.704,51.273z M21.983,40c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19
        S32.459,40,21.983,40z"
                />
              </svg>
            </div>
          </div>
        </div>

        <span
          v-if="isValidKeySearch"
          class="text-red-400 float-left text-left cursor-pointer mt-2"
          @click="cleanFilter"
          >Limpar filtros</span
        >
        <div class="flex items-center justify-between mt-10">
          <h1
            class="text-xl font-normal text-gray-700 dark:text-white md:text-xl"
          >
            Filtrar por ano
          </h1>
        </div>
        <div class="flex flex-wrap justify-start mt-1">
          <span
            v-if="years != null"
            v-for="(item, idx) in years"
            @click="
              () => {
                form.year = item.year
                search()
              }
            "
            :class="
              `inline-block rounded-full cursor-pointer text-white  px-2 py-1 text-xs font-bold mr-3  ${
                form.year == item.year
                  ? 'bg-green-500 dark:bg-black'
                  : getRefValue('cardPrimaryBgHoverColor')
              }`
            "
            >{{ item.year }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, watchEffect } from 'vue'
import API from './../../api/api.js'
import { ENTIDADE } from './../../config'
import useSettings from './../../store/settings'
import { Spin } from 'ant-design-vue'
import ShimmerCard from './../../components/ShimmerCard'
import CardPost from './../../components/CardPost'
import { notification } from 'ant-design-vue'

export default {
  components: {
    Spin,
    ShimmerCard,
    notification,
    CardPost,
  },
  setup() {
    const app = getCurrentInstance()

    const { getRefValue } = useSettings()

    const data = ref(null)
    const years = ref(null)
    const loading = ref(false)
    const error = ref(false)
    const form = ref({ key: '', year: '' })

    const fetchPosts = async () => {
      function compare(a, b) {
        if (a.id < b.id) return 1
        else if (a.id > b.id) return -1
        else return 0
      }

      try {
        loading.value = true
        const res = await API.get(`postagem/entidade/${ENTIDADE}`)
        data.value = res.data
        data.value.sort(compare)
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
      } finally {
        loading.value = false
        isSearchResults.value = false
      }
    }

    const fetchYears = async () => {
      try {
        loading.value = true
        const res = await API.get(`postagem/years/entidade/${ENTIDADE}`)
        years.value = res.data
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
      } finally {
        loading.value = false
      }
    }

    const search = async () => {
      try {
        loading.value = true
        const res = await API.post(`postagem/search/entidade/${ENTIDADE}`, {
          key: form.value.key != '' ? form.value.key : null,
          year: form.value.year != '' ? form.value.year : null,
        })
        data.value = res.data
        isSearchResults.value = true
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
        isSearchResults.value = false
      } finally {
        loading.value = false
      }
    }

    const cleanFilter = () => {
      form.value.key = ''
      form.value.year = ''
      fetchPosts()
    }

    const isValidKeySearch = ref(false)
    const isSearchResults = ref(false)

    onMounted(() => {
      fetchPosts()
      fetchYears()
    })

    watchEffect(() => {
      form.value.key.length > 0
        ? (isValidKeySearch.value = true)
        : (isValidKeySearch.value = false)
    })

    return {
      loading,
      error,
      data,
      form,
      search,
      cleanFilter,
      isValidKeySearch,
      isSearchResults,
      fetchYears,
      years,
      getRefValue,
    }
  },
}
</script>
