<template>
  <div
    v-if="loading || data == null"
    class="center-content max-h-full"
    style="height: 100vh;"
  >
    <Spin class="align-middle mt-36" tip="Carregando..."> </Spin>
  </div>
  <div
    v-if="!loading && data != null"
    class="mx-auto  pt-8 md:pt-20 justify-center bg-white dark:bg-black"
  >
    <div v-for="(item, idx) in data" :key="idx">
      <router-link
        v-if="item.route"
        :to="PUBLIC_PATH + item.route"
        v-html="item.conteudo"
      >
      </router-link>
      <video-background
        v-else-if="item.ref == 'VIDEO'"
        :src="item.conteudo"
        style="height: 92vh;"
        objectFit="fill"
        overlay="linear-gradient(45deg,#000,#ffffff17)"
      >
        <div class="flex h-screen">
          <div class="m-auto">
            <p class="text-5xl  text-center text-white inline-block">
              {{ item.titulo }}
            </p>
          </div>
        </div>
      </video-background>
      <div class="w-full overflow-hidden" v-else-if="item.ref == 'SLIDESHOW'">
        <Carousel>
          <Slide v-for="slide in stringToArray(item.conteudo)" :key="slide">
            <div
              class="carousel__item__without_padding"
              :style="`background-image: url('${slide.img}')`"
            >
              <p class="text-5xl">{{ slide.text }}</p>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
      <div class="dark:text-white" v-else v-html="item.conteudo"></div>
    </div>
  </div>
</template>

<script>
import { ENTIDADE, PARAMS, PUBLIC_PATH } from './../../config'
import { ref, onMounted, computed, watchEffect } from 'vue'
import API from './../../api/api'
import useSettings from './../../store/settings'
import { Spin } from 'ant-design-vue'

import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Spin,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    const loading = ref(false)
    const data = ref(null)
    const error = ref(false)

    const { getRefValue } = useSettings()

    const fetchContent = async () => {
      try {
        loading.value = true
        const res = await API.get(`conteudo/entidade/${ENTIDADE}`)
        if (res.status == 200) {
          data.value = res.data.filter(
            (e) => e.tipo == 'DYNAMIC' || e.tipo == 'STATIC'
          )
        } else {
          error.value = true
        }
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    const renderHtml = (key) => {
      const res = data.value.filter((e) => e.ref == key).shift()

      return res
    }

    const qtdSlides = ref(0)
    const currentSlide = ref(0)
    const interval = ref()
    const orderScroll = ref('ASC')

    const autoScroll = () => {
      const btn = document.getElementsByClassName('carousel__next')[0]
      const btnPrev = document.getElementsByClassName('carousel__prev')[0]
      interval.value = setInterval(() => {
        if (
          currentSlide.value < qtdSlides.value &&
          orderScroll.value == 'ASC'
        ) {
          if (btn != undefined) {
            btn.click()
            currentSlide.value++
          }
        } else if (currentSlide.value > 0 && orderScroll.value == 'DESC') {
          if (btnPrev != undefined) {
            btnPrev.click()
            currentSlide.value--
          }
        } else if (
          currentSlide.value == qtdSlides.value &&
          orderScroll.value == 'ASC'
        ) {
          if (btnPrev != undefined) {
            orderScroll.value = 'DESC'
            btnPrev.click()
            currentSlide.value--
          }
        } else {
          if (btn != undefined) {
            orderScroll.value = 'ASC'
            btn.click()
            currentSlide.value++
          }
        }
      }, 5000)
    }

    onMounted(async () => {
      await fetchContent()
      // fetchAllClassText()
      let btn = document.getElementsByClassName('carousel__next')
      if (btn.length > 0) {
        btn = btn[0]
        autoScroll()
        btn.addEventListener('click', function() {
          clearInterval(interval.value)
          autoScroll()
        })
      }
    })

    const cardStyle = `max-w-screen-lg shadow-2xl rounded-lg mx-auto text-center p-12 mt-4 ${getRefValue(
      'cardPrimaryBgColor'
    )}`

    const typeBackground = (type) => {
      if (type.split('.').pop() == 'mp4') return 'VIDEO'
      if (
        type.split('.').pop() == 'png' ||
        type.split('.').pop() == 'jpg' ||
        type.split('.').pop() == 'jpeg'
      )
        return 'IMAGE'
      return 'DESATIVADO'
    }

    const stringToArray = (code) => {
      const elements = []
      code = code.replace('[{', '{')
      code = code.replace('}]', '}')
      code = code.split('},{')
      if (code.length == 1) {
        elements.push(JSON.parse(code[0]))
      } else {
        for (let i = 0; i < code.length; i++) {
          if (i == 0) {
            code[i] = code[i] + '}'
          } else if (i < code.length - 1) {
            code[i] = '{' + code[i] + '}'
          } else {
            code[i] = '{' + code[i]
          }
          elements.push(JSON.parse(code[i]))
        }
      }
      qtdSlides.value = elements.length
      return elements
    }

    return {
      getRefValue,
      loading,
      renderHtml,
      data,
      error,
      cardStyle,
      typeBackground,
      PUBLIC_PATH,
      stringToArray,
      currentSlide,
      autoScroll,
    }
  },
  // computed: {
  //   cardStyle: getRefValue("cardPrimaryBgColor"),
  // },
}
</script>
<style scoped>
.img-left {
  width: 40vw;
  max-width: 250px;
  min-width: 200px;
}
</style>
