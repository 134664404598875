<template>
  <div class="mx-auto pt-24  md:pt-28 container bg-white dark:bg-black">
    <nav class="pr-3 ml-10 md:ml-2 rounded font-sans w-full mt-10 pt-5 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li>
          <router-link
            :to="PUBLIC_PATH + 'prefeitura'"
            class="no-underline text-blue dark:text-white font-bold"
            >Prefeitura</router-link
          >
        </li>
      </ol>
    </nav>
    <div class="p-10">
      <img
        :src="params.foto"
        class="inline-block  cursor-pointer object-scale-down mb-5"
        onerror="this.onerror=null;this.src='https://ide.geobases.es.gov.br/uploaded/thumbs/layer-77ae0b6d-0e02-4dad-b76b-f728480a38b1-thumb.png';"
      />
    </div>
    <h1
      class="w-full text-xl font-bold break-words leading-tight  px-10 text-center text-gray-800 dark:text-white"
    >
      {{ params.nome }}
    </h1>
    <span
      :class="
        `
              inline-block rounded-full cursor-spanointer text-white  px-2 py-1 text-xs font-bold my-2  
               
            ${getRefValue('cardSecretariaBgColor')}`
      "
    >
      {{ params.cargo }}
    </span>
    <div
      class="ql-editor  px-10 md:px-40 text-black dark:text-white"
      v-html="params.descricao"
    ></div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import useSettings from './../../store/settings'
import { PUBLIC_PATH } from './../../config'

export default {
  setup() {
    const route = useRoute()
    const params = computed(() => route.params)
    const { getRefValue } = useSettings()

    return {
      params,
      getRefValue,
      PUBLIC_PATH,
    }
  },
}
</script>

<style></style>
