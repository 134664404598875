<template>
  <router-link
    :to="{
      name: 'BlogPost',
      params: {
        id: post.id,
        title: post.title,
      },
    }"
    class="p-4 md:w-1/3 w-full hover:opacity-90 cursor-pointer hover:text-gray-900 "
  >
    <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
      <img
        v-if="post.cover"
        class="lg:h-48 md:h-36 w-full object-cover object-center"
        :src="post.cover"
        alt="blog"
      />
      <div
        v-else
        class="bg-white dark:bg-black lg:h-48 md:h-36 w-full object-cover object-center "
      ></div>
      <div class="p-6">
        <h1
          class="title-font text-lg font-medium text-gray-900 dark:text-white mb-3"
        >
          {{ post.title }}
        </h1>
        <p
          class="leading-relaxed mb-3  overflow-hidden align-bottom text-gray-900 dark:text-white"
        >
          {{ dateToLocalDate(post.created_at) }}
        </p>
        <p
          class="leading-relaxed mb-3 h-20 overflow-hidden text-black dark:text-white text-justify"
          v-html="post.content"
        ></p>

        <div class="justify-center align-middle flex flex-wrap  mb-2 mt-6">
          <div
            class="no-underline text-blue-500 dark:text-white font-medium border px-4 p-2"
          >
            Ler mais
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { dateToLocalDate } from './../config'
export default {
  props: ['post'],
  setup(props) {
    return {
      post: props.post,
      dateToLocalDate,
    }
  },
}
</script>
