import { createWebHistory, createRouter } from 'vue-router'
import HomePage from './../pages/home/home.page.vue'
import ArquivosPage from './../pages/transparencia/arquivos.vue'
import eSic from './../pages/esic/e-sic.vue'
import eSicSolicitacao from './../pages/esic/solicitacao.vue'
import LAI from './../pages/lai/lai.vue'
import Secretarias from './../pages/institucional/secretaria.vue'
import Setores from './../pages/institucional/setores.vue'
import eSicFAQ from './../pages/esic/faq.vue'
import eSicAcompanhamento from './../pages/esic/acompanhamento.vue'
import eSicResultados from './../pages/esic/resultados.vue'
import Detalhes from './../pages/institucional/detalhes.vue'
import DetalhesSetor from './../pages/institucional/detalhesSetor.vue'
import DetalhesPessoa from './../pages/institucional/detalhesPessoa.vue'
import Transparencia from './../pages/transparencia/transparencia.vue'
import Prefeitura from './../pages/institucional/prefeitura.vue'
import Blog from './../pages/blog/Blog.vue'
import BlogPost from './../pages/blog/BlogPost.vue'
import { PUBLIC_PATH } from './../config'

const routes = [
  {
    path: PUBLIC_PATH,
    name: 'Inicio',
    component: HomePage,
  },
  {
    path: PUBLIC_PATH + 'arquivos/:id/:slug',
    name: 'Arquivos',
    component: ArquivosPage,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'e-Sic',
    name: 'e-Sic',
    component: eSic,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'e-Sic/operacao/:opcao',
    name: 'e-Sic-solicitacao',
    component: eSicSolicitacao,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'e-Sic/acompanhamento',
    name: 'e-Sic-acompanhamento',
    component: eSicAcompanhamento,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'e-Sic/resultados',
    name: 'e-Sic-resultados',
    component: eSicResultados,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'e-Sic/faq',
    name: 'e-Sic-faq',
    component: eSicFAQ,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'LAI',
    name: 'LAI',
    component: LAI,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'secretarias',
    name: 'secretarias',
    component: Secretarias,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'setores',
    name: 'setores',
    component: Setores,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'prefeitura',
    name: 'prefeitura',
    component: Prefeitura,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'transparencia',
    name: 'transparencia',
    component: Transparencia,
    // params: true,
  },
  {
    path: PUBLIC_PATH + 'secretarias/detalhes',
    name: 'detalhes',
    component: Detalhes,
    params: true,
  },
  {
    path: PUBLIC_PATH + 'institucional/prefeitura/pessoa/',
    name: 'detalhesPessoa',
    component: DetalhesPessoa,
    params: true,
  },
  {
    path: PUBLIC_PATH + 'setores/detalhes',
    name: 'detalhesSetor',
    component: DetalhesSetor,
    params: true,
  },
  {
    path: PUBLIC_PATH + 'blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: PUBLIC_PATH + 'blog/post/:id/:title',
    name: 'BlogPost',
    component: BlogPost,
    params: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
})

export default router
