<template>
  <div
    class="mx-auto pt-24  md:pt-28 container  arquivos bg-white dark:bg-black"
  >
    <br />

    <div
      v-if="loading"
      class="container w-full flex  flex-wrap justify-center "
    >
      <ShimmerCard height="h-96" width="w-1/5" />
      <ShimmerCard height="h-96" width="w-1/5" />
      <ShimmerCard height="h-96" width="w-1/5" />
      <ShimmerCard height="h-96" width="w-1/5" />
      <ShimmerCard height="h-96" width="w-1/5" />
    </div>
    <div v-if="!loading && pessoas != null" class="w-full">
      <p
        class="font-medium text-2xl text-black dark:text-white mb-10 text-center w-full"
      >
        Prefeitura
      </p>
      <div class="w-full mx-auto flex flex-wrap justify-center">
        <div
          class="w-full md:w-1/5 rounded overflow-hidden shadow-lg my-2 mx-4"
          v-for="(item, idx) in pessoas"
          :key="idx"
        >
          <img class="w-full" :src="item.foto" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 text-black dark:text-white">
              {{ item.nome }}
            </div>
            <div class="font-semibold text-base mb-2 ">
              <span
                :class="
                  `
              inline-block rounded-full cursor-spanointer text-white  px-2 py-1 text-base font-bold my-2  
               
            ${getRefValue('navbarBgColor')}`
                "
              >
                {{ item.cargo }}
              </span>
            </div>
            <!-- <p class="text-grey-darker text-base">
              {{ item.descricao }}
            </p> -->
            <div class="w-full flex flex-wrap mt-4  justify-between">
              <FacebookOutlined
                class="text-3xl cursor-pointer"
                v-if="item.facebook"
                @click="openLink(item.facebook)"
              />
              <InstagramOutlined
                class="text-3xl cursor-pointer"
                v-if="item.instagram != null"
                @click="openLink(item.instagram)"
              />
              <WhatsAppOutlined
                class="text-3xl cursor-pointer"
                v-if="item.whatsapp != null"
                @click="openLink(item.whatsapp)"
              />
              <TwitterOutlined
                class="text-3xl cursor-pointer"
                v-if="item.twitter != null"
                @click="openLink(item.twitter)"
              />
              <LinkedinOutlined
                class="text-3xl cursor-pointer"
                v-if="item.linkedin != null"
                @click="openLink(item.linkedin)"
              />
              <YoutubeOutlined
                class="text-3xl cursor-pointer"
                v-if="item.youtube != null"
                @click="openLink(item.youtube)"
              />
              <MessageOutlined
                class="text-3xl cursor-pointer"
                v-if="item.site != null"
                @click="openLink(item.site)"
              />
              <IdcardOutlined
                class="text-3xl cursor-pointer"
                v-if="item.site"
                @click="openLink(item.site)"
              />
            </div>
          </div>
          <div class="justify-center align-middle flex flex-wrap  mb-10 mt-6">
            <router-link
              :to="{
                name: 'detalhesPessoa',
                params: item,
              }"
              class="no-underline text-blue-500 dark:text-white font-medium border px-4 p-2"
              >Ver mais</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import API from './../../api/api.js'
import { ENTIDADE, openLink } from './../../config'
import { notification, Spin } from 'ant-design-vue'
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  MessageOutlined,
  IdcardOutlined,
} from '@ant-design/icons-vue'
import useSettings from './../../store/settings'
import ShimmerText from '@/components/ShimmerText'
import ShimmerCard from '@/components/ShimmerCard'

export default {
  components: {
    FacebookOutlined,
    ShimmerText,
    ShimmerCard,
    InstagramOutlined,
    WhatsAppOutlined,
    TwitterOutlined,
    LinkedinOutlined,
    YoutubeOutlined,
    MessageOutlined,
    IdcardOutlined,
    Spin,
  },
  setup() {
    const pessoas = ref(null)

    const loading = ref(true)
    const error = ref(null)

    const { getRefValue } = useSettings()

    const fetchPessoas = async () => {
      try {
        loading.value = true
        const res = await API.get(`pessoa/entidade/${ENTIDADE}`)
        pessoas.value = res.data
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      fetchPessoas()
    })

    return {
      loading,
      pessoas,
      error,
      openLink,
      getRefValue,
    }
  },
}
</script>
<style scoped>
.img-left {
  width: 40vw;
  max-width: 250px;
  min-width: 200px;
}
</style>
