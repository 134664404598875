<template>
  <div class="container mx-auto pt-24 md:pt-28 bg-white dark:bg-black">
    <div class="flex flex-wrap h-full align-middle justify-center">
      <nav class="pr-3 ml-10 md:ml-2 rounded font-sans w-full mt-10 mb-5">
        <ol class="list-reset flex text-grey-dark">
          <li>
            <router-link
              :to="PUBLIC_PATH"
              class="no-underline text-blue dark:text-white font-bold"
              >Início</router-link
            >
          </li>
          <li><span class="mx-2">/</span></li>
          <li>
            <router-link
              :to="PUBLIC_PATH + 'e-Sic'"
              class="no-underline text-blue dark:text-white font-bold"
              >Ouvidoria</router-link
            >
          </li>
          <li><span class="mx-2">/</span></li>
          <li class="text-black dark:text-white">Resultados</li>
        </ol>
      </nav>
      <div class="mt-2 mx-5 md:mx-0 w-full justify-end">
        <div class="flex flex-wrap  float-right">
          <div class=" px-3 mb-4 float-right">
            <InputNumber
              class="w-full  float-right"
              placeholder="Ano"
              v-model:value="ano"
              :max="3000"
            />
          </div>
          <div class=" px-3 mb-4  float-right">
            <Button
              type="primary"
              class="w-full float-right"
              @click="consultar()"
              >Pesquisar</Button
            >
          </div>
        </div>
      </div>
      <div class="w-full">
        <p class="font-medium text-2xl text-black dark:text-white">
          Resultados
        </p>
        <Spin tip="Enviando..." :spinning="loading">
          <div class="text-justify container text-lg mt-5 mx-10 md:mx-2">
            <Table :columns="columns" :data-source="data"> </Table>
          </div>
        </Spin>
      </div>
    </div>
  </div>
</template>

<script>
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import API from './../../api/api.js'
import { ref, onMounted } from 'vue'
import { Table, Spin, InputNumber, Button } from 'ant-design-vue'
export default {
  components: {
    Table,
    Spin,
    InputNumber,
    Button,
  },
  setup() {
    const data = ref(null)

    const ano = ref('')
    const loading = ref(false)

    const columns = [
      {
        title: 'Data de solicitação',
        dataIndex: 'data',
        key: 'data',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ]

    const consultar = async () => {
      try {
        loading.value = true
        const res = await API.get(`faq/entidade/${ENTIDADE}/resultados`)
        data.value = res.data
        loading.value = false
      } catch (e) {}
    }

    onMounted(() => {
      ano.value = new Date().getFullYear()
      consultar()
    })

    return {
      columns,
      data,
      consultar,
      ano,
      loading,
      PUBLIC_PATH,
    }
  },
}
</script>
