<template>
  <div class="h-full rounded-lg overflow-hidden">
    <div class="p-6">
      <p
        v-for="(item, idx) in 10"
        :key="idx"
        class="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"
      ></p>
      <div class="h-5"></div>
      <p
        v-for="(item, idx) in 10"
        :key="idx"
        class="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"
      ></p>
      <div class="h-5"></div>
      <p
        v-for="(item, idx) in 10"
        :key="idx"
        class="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"
      ></p>
      <div class="h-5"></div>
      <p
        v-for="(item, idx) in 10"
        :key="idx"
        class="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"
      ></p>
      <div class="h-5"></div>
      <p
        v-for="(item, idx) in 10"
        :key="idx"
        class="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"
      ></p>
    </div>
  </div>
</template>
