<template>
  <div class="container mx-auto pt-24  md:pt-28 bg-white dark:bg-black">
    <nav class="pr-3 mx-10 md:mx-2 rounded font-sans w-full mt-10 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li>
          <router-link
            :to="PUBLIC_PATH + 'e-Sic'"
            class="no-underline text-blue dark:text-white font-bold"
            >Ouvidoria</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li class="text-black dark:text-white">{{ opcao }}</li>
      </ol>
    </nav>
    <p class="font-medium text-2xl text-black dark:text-white my-10">
      {{ opcao }}
    </p>
    <div
      class="text-justify mt-4 px-10 md:px-2 text-lg text-black dark:text-white"
    >
      <p>Prezado(a) Cidadã(o).</p>
      <br />
      <p>
        Ao preencher e enviar o formulário, será gerado um Protocolo. Através
        desse Protocolo, será possível verificar a resposta enviada pela
        entidade.
      </p>
      <br />
      <p>
        Agradecemos seu pedido e responderemos dentro dos prazos determinados na
        Lei de Transparência. Também poderá ser apresentado requerimento
        impresso
        <a :href="getRefValue('esicModeloRequerimento')">(clique aqui)</a>.
      </p>
      <br />
      <p>
        Modelo de Requerimento SISTEMA DE INFORMAÇÕES AO CIDADÃO (e-SIC) Sistema
        de Informação ao Cidadão (SIC) do Poder Executivo está disponível para
        atendimento ao público em sua sede no prédio sede da Prefeitura
        Municipal de {{ getRefValue('entidadeNomeCidade') }}.
      </p>
      <br />

      <br />
    </div>
    <div class="flex flex-wrap justify-center w-full">
      <div
        class="w-full lg:w-1/3 bg-white dark:bg-black text-black dark:text-white shadow-md rounded-lg p-5 overflow-hidden mx-auto"
      >
        <div v-html="informacoesContato"></div>
        <!--  -->
      </div>
      <div
        class="w-full mx-2 mt-5 lg:w-2/4 flex flex-col bg-gray-200 dark:bg-black shadow-md rounded-sm overflow-hidden  p-5"
      >
        <Spin tip="Enviando..." :spinning="loading">
          <h2
            class="text-gray-700 dark:text-white font-semibold text-xl tracking-wide mb-2"
          >
            Formulário
          </h2>

          <div class="mb-4">
            <label
              class="block text-gray-700 dark:text-white text-left text-sm font-bold mb-2"
              for="nome"
              meth="s"
            >
              Nome
            </label>
            <Input class="w-full" v-model:value="nome"></Input>
          </div>
          <div class="mb-4">
            <label
              class="block text-gray-700 dark:text-white text-left text-sm font-bold mb-2"
              for="telefone"
            >
              Telefone
            </label>
            <Input class="w-full" v-model:value="telefone"></Input>
          </div>
          <div class="mb-4">
            <label
              class="block text-gray-700 dark:text-white text-left text-sm font-bold mb-2"
              for="email"
            >
              Email
            </label>
            <Input class="w-full" v-model:value="email"></Input>
          </div>
          <div class="mb-4">
            <label
              class="block text-gray-700 dark:text-white text-left text-sm font-bold mb-2"
              for="mensagem"
            >
              Mensagem *
            </label>
            <TextArea v-model:value="mensagem" :rows="4" />
          </div>
          <div class="flex justify-between">
            <span class="text-sm float-left pr-10 text-black dark:text-white"
              >Campos com * são obrigatórios</span
            >
            <button
              class="bg-green-500 dark:bg-white hover:bg-green-700 text-white dark:text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              @click="sendSolicitacao()"
            >
              Enviar solicitação
            </button>
          </div>
        </Spin>
      </div>
    </div>
  </div>
</template>

<script>
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import useSettings from './../../store/settings'
import { onMounted, ref } from 'vue'
import API from './../../api/api.js'
import { Modal, Input, Spin } from 'ant-design-vue'
import { h } from 'vue'
import { useRoute } from 'vue-router'
export default {
  components: {
    Spin,
    Input,
    TextArea: Input.TextArea,
  },
  setup() {
    const route = useRoute()
    let form = {}

    let nome = ref('')
    let email = ref('')
    let telefone = ref('')
    let mensagem = ref('')
    let opcao = ref('')
    let error = ref(null)

    let informacoesContato = ref('')

    let loading = ref(false)

    const visible = ref(false)

    const { getRefValue } = useSettings()

    const handleOk = (e) => {
      visible.value = false
    }

    const fetchContent = async () => {
      try {
        loading.value = true
        const res = await API.get(
          `conteudo/entidade/${ENTIDADE}/SECTION-OUVIDORIA`
        )
        if (res.status == 200) {
          if (res.data.length > 0) {
            informacoesContato.value = res.data[0].conteudo
          } else {
            informacoesContato.value =
              '<p class="font-medium text-red-400 text-base">Ocorreu um erro, tente novamente mais tarde</p>'
          }
        } else {
          error.value = true
        }
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    const sendSolicitacao = async () => {
      if (mensagem.value.length == 0) {
        Modal.warning({
          title: 'Atenção',
          content: 'O campo mensagem é obrigatório',
        })
      } else {
        loading.value = true
        const data = await API.post('faq', {
          nome: nome.value,
          email: email.value,
          telefone: telefone.value,
          mensagem: mensagem.value,
          id_autarquia: ENTIDADE,
          tipo: opcao.value,
        })
        if (data.data.protocolo != null && data.data.protocolo != undefined) {
          nome.value = ''
          telefone.value = ''
          email.value = ''
          mensagem.value = ''
          Modal.success({
            title: 'Sua solicitação foi enviada com sucesso',
            content: h('div', {}, [
              h('p', 'O protocolo da sua solicitação é:'),
              h('br', ''),
              h('b', data.data.protocolo),
              h('br', ''),
              h('br', ''),
              h(
                'p',
                'Guarde este código, você precisará dele para acompanhar o andamento da sua solicitação.'
              ),
              h('br', ''),
              h('p', data.data.message),
            ]),

            onOk() {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          })
        } else {
          const error = () => {
            Modal.error({
              title: 'Ocorreu um erro',
              content: data.data.message,
            })
          }
        }
        loading.value = false
      }
    }

    onMounted(async () => {
      opcao.value = route.params.opcao
      await fetchContent()
    })

    return {
      form,
      nome,
      telefone,
      email,
      mensagem,
      ENTIDADE,
      sendSolicitacao,
      handleOk,
      visible,
      loading,
      getRefValue,
      opcao,
      informacoesContato,
      error,
      PUBLIC_PATH,
    }
  },
}
</script>
