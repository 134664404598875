<template>
  <div class="container mx-auto pt-24  md:pt-28 bg-white dark:bg-black">
    <div class="flex flex-wrap w-full h-full align-middle justify-center">
      <nav class="pr-3 ml-2 rounded font-sans w-full mt-10 mb-5">
        <ol class="list-reset flex text-grey-dark">
          <li>
            <router-link
              :to="PUBLIC_PATH"
              class="no-underline text-blue dark:text-white font-bold"
              >Início</router-link
            >
          </li>
          <li><span class="mx-2">/</span></li>
          <li>
            <router-link
              :to="PUBLIC_PATH + 'e-Sic'"
              class="no-underline text-blue dark:text-white font-bold"
              >Ouvidoria</router-link
            >
          </li>
          <li><span class="mx-2">/</span></li>
          <li class="text-black dark:text-white">
            FAQ - Perguntas e Respostas
          </li>
        </ol>
      </nav>
      <p class="font-medium text-2xl text-black dark:text-white my-10">
        FAQ - PERGUNTAS FREQUENTES
      </p>

      <div v-if="loading || data == null" class=" w-full">
        <ShimmerText />
      </div>
      <div
        v-if="!loading && data != null"
        class="text-justify px-10 xl:px-40 text-lg mt-10 text-black dark:text-white"
        v-for="(item, idx) in data"
      >
        <h1 class="font-bold text-xl justify-start text-black dark:text-white">
          {{ idx + 1 + '. ' + item.pergunta }}
        </h1>

        <p class="indent-sm leading-10 mt-2 mb-10">
          {{ item.resposta }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import API from './../../api/api'
import { Spin } from 'ant-design-vue'
import ShimmerText from '@/components/ShimmerText'
export default {
  components: { Spin, ShimmerText },
  setup() {
    const loading = ref(true)
    const data = ref(null)

    const fetchPerguntasRespostas = async () => {
      loading.value = true
      const res = await API.get(`faq/entidade/${ENTIDADE}`)
      data.value = res.data
      loading.value = false
    }

    onMounted(() => {
      fetchPerguntasRespostas()
    })

    return {
      data,
      loading,
      PUBLIC_PATH,
    }
  },
}
</script>

<style></style>
