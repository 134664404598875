import api from "./api/api";
import moment from "moment";
const ENV = require("./env.js");

export const ENTIDADE = ENV.ID_AUTARQUIA;

export const PUBLIC_PATH = ENV.PUBLIC_PATH;

export const PARAMS = JSON.parse(localStorage.getItem("params"));

/**
 *  Atenção
 *  é necessário que a rota abaixo já esteja adicionada no arquivo
 *  /src/router/index.js
 */
export const ROUTES = [
  { name: "Ouvidoria", route: ENV.PUBLIC_PATH + "e-Sic" },
  { name: "Transparência", route: ENV.PUBLIC_PATH + "transparencia" },
  {
    name: "Diário Oficial",
    route: "https://diariomunicipalaam.org.br/",
    external: true,
  },
  { name: "Notícias", route: ENV.PUBLIC_PATH + "blog" },
  { name: "Setores", route: ENV.PUBLIC_PATH + "setores" },
  { name: "Secretarias", route: ENV.PUBLIC_PATH + "secretarias" },
  { name: "Prefeitura", route: ENV.PUBLIC_PATH + "prefeitura" },
  { name: "Início", route: ENV.PUBLIC_PATH },
];

export const openLink = (link) => {
  window.open(link, "_blank");
};

export const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  },
};

export const fetchInitialSettingsFromApi = async () => {
  try {
    const res = await api.get(`parametros/entidade/${ENTIDADE}`);
    if (res.status == 200) {
      let params = {};
      res.data.forEach((e) => {
        params[e.ref] = e.value;
      });
      await asyncLocalStorage.setItem("params", JSON.stringify(params));
      return params;
    }
  } catch (e) {
    return null;
  }
};

export const dateToLocalDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};
