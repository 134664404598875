<template>
  <div
    class="mx-auto pt-24  md:pt-28 container   arquivos bg-white dark:bg-black"
  >
    <br />

    <p class="font-medium text-2xl text-black dark:text-white my-5">
      Secretarias
    </p>

    <div v-if="loading" class="w-full flex flex-wrap  md:px-28 px-2">
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
      <ShimmerCard height="h-40" width="w-1/2 w-full" />
    </div>
    <p v-if="error"></p>
    <div class="flex flex-wrap pt-3 px-10 md:px-28 pb-10 justify-between">
      <router-link
        :to="{
          name: 'detalhes',
          params: item,
        }"
        :class="`w-full md:w-1/2 p-5`"
        v-for="(item, idx) in data"
        :key="idx"
      >
        <div
          :class="
            `border-1 border-gray-200 rounded-md shadow-xl hover:shadow-2xl overflow-hidden pt-5 cursor-pointer ${getRefValue(
              'cardSecretariaBgColor'
            )} dark:bg-white`
          "
        >
          <div class=" w-full object-cover object-center">
            <img
              :src="item.url_midia"
              class="cursor-pointer h-40 inline-block"
              onerror="this.onerror=null;this.src='https://ide.geobases.es.gov.br/uploaded/thumbs/layer-77ae0b6d-0e02-4dad-b76b-f728480a38b1-thumb.png';"
            />
          </div>
          <div class="pt-6">
            <h2 class=" w-full mb-2 font-semibold text-lg">
              {{ item.titulo }}
            </h2>
          </div>
          <div class="justify-center align-middle flex flex-wrap  mb-5 mt-6">
            <div
              class="no-underline text-blue-500 dark:text-black font-medium border px-4 p-2"
            >
              Ver mais
            </div>
          </div>
        </div>
      </router-link>
      <!-- <router-link
        v-for="(item, idx) in data"
        :key="idx"
        :class="
          ` bg-card-home cursor-pointer justify-center items-center w-full md:w-1/2 h-min md:h-52 p-4 mb-4  `
        "
        :to="{
          name: 'detalhes',
          params: item,
        }"
      >
        <div
          :class="
            `hover:shadow-2xl rounded transition duration-500 hover:scale-100 transform flex flex-wrap pt-3 justify-center px-10  ${getRefValue(
              'cardSecretariaBgColor'
            )}`
          "
        >
          <div class="w-full md:w-2/4 p-2">
            <img
              :src="item.url_midia"
              class="cursor-pointer h-40 inline-block"
            />
          </div>
          <div class="w-full md:w-2/4 p-2 content-center items-center">
            <h1
              class="font-semibold text-xl text-white text-center justify-center align-middle inline-block md:mt-10"
            >
              {{ item.titulo }}
            </h1>
          </div>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Spin } from 'ant-design-vue'
import API from './../../api/api.js'
import { ENTIDADE } from './../../config'
import useSettings from './../../store/settings'
import ShimmerCard from '@/components/ShimmerCard'

export default {
  components: {
    Spin,
    ShimmerCard,
  },
  setup() {
    const data = ref(null)
    const loading = ref(true)
    const error = ref(null)

    const { getRefValue } = useSettings()

    const fetchItems = async () => {
      loading.value = true
      const res = await API.get(`setor/entidade/${ENTIDADE}/SECRETARIA`)
      if (res.status != 200) {
        const error = new Error(res.status)
        error.value.message = 'Ocorreu um erro'
        throw error
      } else {
        data.value = res.data
      }
      loading.value = false
    }

    onMounted(() => {
      fetchItems()
    })

    return {
      fetchItems,
      loading,
      data,
      error,
      getRefValue,
    }
  },
}
</script>
<style scoped>
.img-left {
  width: 40vw;
  max-width: 250px;
  min-width: 200px;
}
</style>
