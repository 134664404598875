<template>
  <div class="container mx-auto pt-24 md:pt-28 bg-white dark:bg-black">
    <nav class="pr-3 ml-2 rounded font-sans w-full mt-10 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li>
          <router-link
            :to="PUBLIC_PATH + 'e-Sic'"
            class="no-underline text-blue dark:text-white font-bold"
            >Ouvidoria</router-link
          >
        </li>
      </ol>
    </nav>
    <p class="font-medium text-2xl text-black dark:text-white">
      O que deseja fazer?
    </p>
    <div class="mx-10 md:mx-2  h-full align-middle justify-center">
      <div class="flex flex-wrap mt-20 justify-around w-full">
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/operacao/Denúncia'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-red-500 dark:bg-white hover:bg-red-600  h-52 justify-center align-middle  flex flex-wrap content-center items-center my-4 mx-1 p-4 rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold w-full"
            >
              Denúncia
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Comunique um ato ilícito praticado contra a administração
              pública</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/operacao/Reclamação'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-yellow-500 dark:bg-white hover:bg-yellow-600 h-52 justify-center align-middle    flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              Reclamação
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Manifeste sua insatisfação com um serviço público</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/operacao/Elogio'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-pink-800 dark:bg-white  hover:bg-pink-900 h-52 justify-center align-middle    flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              Elogio
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Expresse se você está satisfeito com um atendimento público</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/operacao/Solicitação'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-green-500 dark:bg-white  hover:bg-green-600 h-52 justify-center align-middle    flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center align-middle inline-block text-2xl text-white dark:text-black font-semibold justify-center"
            >
              Solicitação
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Paça um atendimento ou uma prestação de serviço</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/operacao/Sugestão'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-yellow-600 dark:bg-white hover:bg-yellow-700  h-52 justify-center align-middle    flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              Sugestão
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Envie uma ideia ou proposta de melhoria dos serviços
              públicos</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/resultados'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-indigo-800 dark:bg-white hover:bg-indigo-900 h-52 justify-center align-middle    flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              Resultados
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Resultados das últimas solicitações</span
            >
          </div>
        </router-link>
        <router-link
          :to="PUBLIC_PATH + 'e-Sic/acompanhamento'"
          class="w-full md:w-3/12"
        >
          <div
            class="bg-green-800 dark:bg-white hover:bg-green-900 h-52 justify-center align-middle   flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              Acompanhamento
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Acompanhe o andamento do seu protocolo</span
            >
          </div>
        </router-link>
        <router-link :to="PUBLIC_PATH + 'e-Sic/faq'" class="w-full md:w-3/12">
          <div
            class="bg-red-800 dark:bg-white hover:bg-red-900 h-52 justify-center align-middle  flex flex-wrap content-center items-center my-4 mx-1 p-4  rounded-sm"
          >
            <p
              class="text-center inline-block align-middle text-2xl text-white dark:text-black font-semibold"
            >
              F.A.Q
            </p>
            <span class="text-white dark:text-black w-full text-xl"
              >Perguntas feitas com frequência e já respondidas</span
            >
          </div>
        </router-link>
      </div>

      <p class="font-medium text-2xl text-black dark:text-white  mt-10">
        Como fazer sua solicitação de acesso à informação pública
      </p>
      <br />
      <!-- <div class="flex flex-wrap mt-10">
        <div class="w-full lg:w-1/4 mb-4 px-2 align-middle text-center">
          <router-link to="/e-Sic/operacao">
            <img
              :src="getRefValue('baseUrl') + '/img/esic-solicitacao.png'"
              class="inline-block w-36"
            />
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 mb-4 px-2 align-middle text-center">
          <router-link to="/e-Sic/resultados">
            <img
              :src="getRefValue('baseUrl') + '/img/esic-resultados.png'"
              class="inline-block w-36"
            />
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 mb-4 px-2 align-middle text-center">
          <router-link to="/e-Sic/acompanhamento">
            <img
              :src="getRefValue('baseUrl') + '/img/esic-acompanhamento.png'"
              class="inline-block w-36"
            />
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 mb-4 px-2 align-middle text-center">
          <router-link to="/e-Sic/faq">
            <img
              :src="getRefValue('baseUrl') + '/img/faq.fw.png'"
              class="inline-block w-36"
            />
          </router-link>
        </div>
      </div> -->
      <h3 class="text-lg text-justify text-black dark:text-white mt-10">
        Caso não tenha acesso à internet, ou prefira apresentar o seu pedido de
        informação em meio físico (impresso) clique aqui e acesse um dos
        formulários disponíveis.
        <br /><br />
        Prezado Cidadão(ã), através da Lei nº 12.527/2011 (Lei de Acesso à
        Informação – LAI), é disponibilizado o e-SIC (Sistema Eletrônico do
        Serviço de Informações ao Cidadão) que permite que qualquer pessoa,
        física ou jurídica, encaminhe pedidos de acesso à informação.
        <br /><br />
        Por meio do sistema, além de fazer o pedido, será possível: acompanhar a
        solicitação através do número de protocolo gerado.
        <br /><br />
        O prazo para recebimento de resposta, obedece a Lei supracitada, será de
        20 (vinte) dias, sendo prorrogável por mais 10 (dez) dias.
        <br /><br />
        Agradecemos de antemão sua atençaõ e compreensão.
      </h3>
    </div>
  </div>
</template>

<script>
import useSettings from './../../store/settings'
import { PUBLIC_PATH } from './../../config'
export default {
  setup() {
    const { getRefValue } = useSettings()
    return { getRefValue, PUBLIC_PATH }
  },
}
</script>
