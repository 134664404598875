<template>
  <div class="align-middle mt-10 w-full">
    <div v-if="loading">
      <Spin tip="Carregando..." :spinning="loading"></Spin>
    </div>
    <footer
      v-if="(footer == null || footer.status == 0) && !loading"
      :class="
        `text-gray-600 dark:text-white body-font ${getRefValue(
          'footerBgColor'
        )} dark:bg-white ${getRefValue('footerTextSize')} ${getRefValue(
          'footerTextColor'
        )}`
      "
    >
      <div
        class="container px-5 py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col"
      >
        <div
          class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center"
        >
          <div class="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2
              :class="
                `title-font font-bold  tracking-widest text-sm mb-3 ${getRefValue(
                  'footerTextColor'
                )} dark:text-black `
              "
            >
              Notícias
            </h2>
            <nav class="list-none mb-10">
              <li>
                <router-link :to="PUBLIC_PATH + 'blog'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Últimas notícias</a
                  >
                </router-link>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2
              :class="
                `title-font font-bold  tracking-widest text-sm mb-3 ${getRefValue(
                  'footerTextColor'
                )} dark:text-black `
              "
            >
              Institucional
            </h2>
            <nav class="list-none mb-10">
              <li>
                <router-link :to="PUBLIC_PATH + 'prefeitura'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Prefeitura</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'secretarias'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Secretarias</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'setores'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Setores</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Sobre a cidade</a
                  >
                </router-link>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2
              :class="
                `title-font font-bold  tracking-widest text-sm mb-3 ${getRefValue(
                  'footerTextColor'
                )} dark:text-black `
              "
            >
              Transparência
            </h2>
            <nav class="list-none mb-10">
              <li>
                <router-link :to="PUBLIC_PATH + 'transparencia'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Consultar</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'arquivos/131/COVID%2019'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >COVID-19</a
                  >
                </router-link>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2
              :class="
                `title-font font-bold  tracking-widest text-sm mb-3 ${getRefValue(
                  'footerTextColor'
                )} dark:text-black `
              "
            >
              Ouvidoria
            </h2>
            <nav class="list-none mb-10">
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/operacao/Denúncia'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Denúncia</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/operacao/Reclamação'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Reclamação</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/operacao/Elogio'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Elogio</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/operacao/Solicitação'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Solicitação</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/operacao/Sugestão'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Sugestão</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/resultados'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Resultados</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/acompanhamento'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >Acompanhamento</a
                  >
                </router-link>
              </li>
              <li>
                <router-link :to="PUBLIC_PATH + 'e-Sic/faq'">
                  <a
                    :class="
                      `hover:${getRefValue(
                        'footerTextHoverColor'
                      )} ${getRefValue(
                        'footerTextColor'
                      )} dark:text-black ${getRefValue('footerTextSize')}`
                    "
                    >F.A.Q</a
                  >
                </router-link>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2
              :class="
                `title-font font-bold  tracking-widest text-sm mb-3 ${getRefValue(
                  'footerTextColor'
                )} dark:text-black `
              "
            >
              Diário ofícial
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a
                  href="https://diariomunicipalaam.org.br/"
                  target="_blank"
                  :class="
                    `hover:${getRefValue('footerTextHoverColor')} ${getRefValue(
                      'footerTextColor'
                    )} ${getRefValue('footerTextSize')}`
                  "
                  >Acessar</a
                >
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 dark:bg-black justify-center">
        <div
          class="container mx-auto justify-center text-center py-4 px-5 flex flex-wrap flex-col sm:flex-row"
        >
          <p
            class="text-gray-500 dark:text-white text-sm text-center sm:text-left"
          >
            {{ getRefValue('entidadeNome') }} - AM © {{ ano }} —
            <a
              href="https://www.transparencia-am.com.br"
              rel="noopener noreferrer"
              class="text-gray-600 dark:text-white ml-1"
              target="_blank"
              >ANC - Tecnologia da Informação</a
            >
          </p>
        </div>
      </div>
    </footer>
    <div v-else-if="!loading && footer != null">
      <div v-html="footer"></div>
    </div>
  </div>
</template>

<script>
import useSettings from './../store/settings'
import { Layout, Spin } from 'ant-design-vue'
import { ENTIDADE, PUBLIC_PATH } from './../config'
import API from './../api/api.js'
import { onMounted, ref } from 'vue'
export default {
  components: { Layout, Spin },
  setup() {
    const { getRefValue } = useSettings()
    const footer = ref(null)
    const loading = ref(false)

    const fetchContent = async () => {
      try {
        loading.value = true
        const res = await API.get(
          `conteudo/entidade/${ENTIDADE}/SECTION-FOOTER`
        )
        if (res.status == 200) {
          if (res.data.length > 0) {
            footer.value = res.data[0].conteudo
          }
        }
      } catch (e) {
        footer.value = null
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchContent()
    })

    return {
      getRefValue,
      ano: new Date().getFullYear(),
      PUBLIC_PATH,
      footer,
      loading,
    }
  },
}
</script>

<style>
.footer {
  margin-top: 10vh;
  background-color: aqua;
  width: 100%;
}
</style>
