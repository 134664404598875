<template>
  <div class="mx-auto pt-24  md:pt-28 container bg-white dark:bg-black">
    <nav class="pr-3 ml-10 md:ml-2 rounded font-sans w-full mt-10 pt-5 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li>
          <router-link
            :to="PUBLIC_PATH + 'setores'"
            class="no-underline text-blue dark:text-white font-bold"
            >Setores</router-link
          >
        </li>
      </ol>
    </nav>
    <div class="p-10">
      <img
        :src="params.url_midia"
        class="inline-block w-60 cursor-pointer object-scale-down mb-10"
        onerror="this.onerror=null;this.src='https://ide.geobases.es.gov.br/uploaded/thumbs/layer-77ae0b6d-0e02-4dad-b76b-f728480a38b1-thumb.png';"
      />
    </div>
    <h1
      class="w-full text-xl font-bold break-words leading-tight my-10 px-10 text-center text-gray-800 dark:text-white"
    >
      {{ params.titulo }}
    </h1>
    <div
      class="ql-editor px-10 md:px-40 text-black dark:text-white"
      v-html="params.conteudo"
    ></div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { PUBLIC_PATH } from './../../config'

export default {
  setup() {
    const route = useRoute()
    const params = computed(() => route.params)

    return {
      params,
      PUBLIC_PATH,
    }
  },
}
</script>

<style></style>
