import { createApp } from "vue";
import App from "./App.vue";
// import Antd from "ant-design-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "ant-design-vue/dist/antd.css";
import "./assets/css/global.css";
import "./index.css";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css";
import router from "./router";
import VideoBackground from "vue-responsive-video-background-player";

const app = createApp(App);

app.component("video-background", VideoBackground);
app.config.productionTip = true;
app.config.globalProperties.axios = axios;
app.prototype;
// app.use(Antd);
app.use(router);
app.use(VueAxios, axios);

app.mount("#app");
