<template>
  <div
    class="mx-auto pt-24  md:pt-28 container  h-screen bg-white dark:bg-black"
  >
    <nav
      class="pr-3 pl-10 md:pl-0 pt-5 md:mx-2 rounded font-sans w-full mt-10 mb-5"
    >
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li>
          <router-link
            :to="PUBLIC_PATH + 'e-Sic'"
            class="no-underline text-blue dark:text-white font-bold"
            >Ouvidoria</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <li class="text-black dark:text-white">Acompanhamento</li>
      </ol>
    </nav>
    <p class="font-medium text-2xl text-black dark:text-white my-10">
      Acompanhamento
    </p>
    <Spin tip="Consultando..." :spinning="loading">
      <div class="mb-4 mx-10 md:mx-2">
        <label
          class="block text-gray-700 dark:text-white text-sm font-bold mb-2 text-left"
          for="email"
        >
          Número do protocolo
        </label>
        <Input
          class="w-full"
          v-model:value="protocolo"
          placeholder="Ex: P2191220220210102120"
        ></Input>
      </div>
      <div class="flex justify-end mb-20 mr-10 md:mr-2">
        <button
          @click="consultar()"
          id="submit"
          class="bg-green-500 dark:bg-white hover:bg-green-700 text-white dark:text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline sm:block"
        >
          Consultar
        </button>
      </div>
    </Spin>
  </div>
</template>

<script>
import { API_URL, ENTIDADE, BASE_URL, PUBLIC_PATH } from './../../config'
import { Input, Spin } from 'ant-design-vue'
import { ref } from 'vue'
import API from './../../api/api.js'
import { Modal } from 'ant-design-vue'
import { h } from 'vue'
export default {
  components: {
    Input,
    Spin,
  },
  setup() {
    let protocolo = ref('')

    let loading = ref(false)

    const consultar = async () => {
      loading.value = true
      const data = await API.get('faq/protocolo/' + protocolo.value)
      if (data.data.protocolo == null || data.data.protocolo == undefined) {
        Modal.warning({
          title: 'Atenção',
          content: 'Protocolo não encontrado, verique se digitou corretamente',
        })
      } else {
        Modal.success({
          title: 'Informações encontradas',
          content: h('div', {}, [
            h('p', 'Protocolo:'),
            h('b', data.data.protocolo),
            h('br', ''),
            h('br', ''),
            h('p', 'Status:'),

            h('b', data.data.status),
            h('br', ''),
            h('br', ''),
            h('p', 'Solicitação:'),

            h('b', data.data.mensagem),
            h('br', ''),
            h('br', ''),
            h('p', 'Resposta:'),
            h('br', ''),
            h('b', data.data.resposta),
            h('br', ''),
            h('br', ''),
            h('p', 'Data da solicitação:'),
            h('b', data.data.data),
            h('br', ''),
            h('br', ''),
            h(
              'p',
              'A previsão de resposta é de até 20 dias após a data da solicitação'
            ),
          ]),

          onOk() {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        })
      }
      loading.value = false
    }

    return {
      protocolo,
      loading,
      PUBLIC_PATH,
      consultar,
    }
  },
}
</script>
