<template>
  <div
    v-if="loading || PARAMS == null"
    class="center-content max-h-full "
    style="height: 100vh;"
  >
    <Spin class="align-middle mt-36" tip="Aguarde..."> </Spin>
  </div>
  <div v-if="!loading && PARAMS != null" class="bg-white dark:bg-black">
    <NavBarVue />
    <div id="body-site" class="bg-white dark:bg-black">
      <router-view />
    </div>
    <div
      class="w-12  bg-blue-500 dark:bg-white py-2 px-1 rounded-l-lg align-middle text-center justify-between shadow-xl fixed right-0"
      style="top: 40%;"
    >
      <p
        class="text-white dark:text-black text-center font-semibold cursor-pointer"
        style="font-size: 20px"
        @click="fontChange('UP')"
      >
        A +
      </p>

      <div class="bg-blue-600 dark:bg-white w-full h-0.5"></div>
      <p
        class="text-white dark:text-black text-center  font-semibold cursor-pointer"
        style="font-size: 20px"
        @click="fontChange('DOWN')"
      >
        A -
      </p>
      <div class="bg-blue-600 dark:bg-white w-full h-0.5"></div>
      <p
        class="text-white dark:text-black text-center  font-semibold cursor-pointer"
        style="font-size: 20px"
        @click="fontChange('RESET')"
      >
        Aa
      </p>
      <div class="bg-blue-600 dark:bg-white w-full h-0.5"></div>
      <div class="dark:bg-gray-400 rounded-md">
        <p
          class="text-white dark:text-black text-center  font-semibold cursor-pointer"
          style="font-size: 20px"
          @click="contrast()"
        >
          M
        </p>
      </div>
    </div>
    <BackTop />
    <FooterVue />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import api from './api/api'
import FooterVue from './components/Footer.vue'
import NavBarVue from './components/NavBar.vue'
import useSettings from './store/settings'
import { ENTIDADE, PUBLIC_PATH } from './config'
import { Spin, BackTop } from 'ant-design-vue'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    NavBarVue,
    FooterVue,
    Spin,
    BackTop,
  },
  setup() {
    const routes = useRouter()
    const { loading, PARAMS, fetchSettings, getRefValue } = useSettings()
    const actualZoom = ref(100)

    onMounted(async () => {
      await fetchSettings()
      const virtualRoute = window.location.href.split('?')
      document.title = getRefValue('entidadeNome')
      if (virtualRoute.length > 1) {
        routes.push(PUBLIC_PATH + virtualRoute[1])
      }
    })

    const fontChange = (action) => {
      const fator = 2
      if (document.body.style.zoom == '') {
        document.body.style.zoom = '100%'
      }
      if (action == 'UP') {
        if (actualZoom.value < 200) {
          document.body.style.zoom = actualZoom.value + fator + '%'
          actualZoom.value += fator
        }
      } else if (action == 'DOWN') {
        if (actualZoom.value > 50) {
          document.body.style.zoom = actualZoom.value - fator + '%'
          actualZoom.value -= fator
        }
      } else if (action == 'RESET') {
        document.body.style.zoom = '100%'
      }
    }

    const contrast = () => {
      let htmlClasses = document.querySelector('html').classList
      if (localStorage.theme == 'dark') {
        htmlClasses.remove('dark')
        localStorage.removeItem('theme')
      } else {
        htmlClasses.add('dark')
        localStorage.theme = 'dark'
      }
    }

    return {
      loading,
      PARAMS,
      getRefValue,
      fontChange,
      contrast,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 80px;
  padding: 0;
  margin: 0;
}
</style>
