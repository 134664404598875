<template>
  <div
    :class="
      `font-sans antialiased w-full  ${
        getRefValue('navbarFixed') == 'true' ? ' top-0 z-30 fixed ' : ''
      }`
    "
  >
    <nav
      :class="
        `flex items-center justify-between flex-wrap p-6 ${getRefValue(
          'navbarBgColor'
        )} dark:bg-white`
      "
    >
      <div
        :class="
          `flex items-center flex-no-shrink mr-6 ${getRefValue(
            'navbarTextTitleColor'
          )} dark:text-black `
        "
      >
        <span
          :class="
            `font-semibold text-2xl tracking-tight ${getRefValue(
              'navbarTextTitleSize'
            )} `
          "
          >{{ getRefValue('navbarNomeEntidade') }}
        </span>
      </div>
      <div class="block sm:hidden">
        <button
          @click="toggle"
          class="flex items-center px-3 py-2 bg-transparent dark:bg-black  rounded text-teal-lighter "
        >
          <svg
            v-if="!open"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-menu-2"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
          </svg>
          <svg
            v-if="open"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrows-minimize"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="5 9 9 9 9 5" />
            <line x1="3" y1="3" x2="9" y2="9" />
            <polyline points="5 15 9 15 9 19" />
            <line x1="3" y1="21" x2="9" y2="15" />
            <polyline points="19 9 15 9 15 5" />
            <line x1="15" y1="9" x2="21" y2="3" />
            <polyline points="19 15 15 15 15 19" />
            <line x1="15" y1="15" x2="21" y2="21" />
          </svg>
          <!-- <svg
            :class="`h-3 w-3 ${getRefValue('navbarMobileMenuIconColor')}`"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg> -->
        </button>
      </div>
      <div
        :class="open ? 'block' : 'hidden'"
        class="w-full items-end flex-grow sm:flex sm:items-center sm:w-auto"
      >
        <div class="text-sm sm:flex-grow justify-end  items-end">
          <div v-for="(item, idx) in ROUTES" :key="idx">
            <a
              @click="toggle"
              v-if="item.external"
              target="_blank"
              :class="
                `no-underline  block mt-4 md:float-right sm:inline-block sm:mt-0 text-teal-lighter mr-4 ${getRefValue(
                  'navbarTextItemSize'
                )} ${getRefValue(
                  'navbarTextItemColor'
                )} dark:text-black  hover:${getRefValue(
                  'navbarTextItemHoverColor'
                )}`
              "
              :href="item.route"
            >
              <div class="flex flex-wrap justify-center">
                <span>{{ item.name }}</span>
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                  />
                  <line x1="10" y1="14" x2="20" y2="4" />
                  <polyline points="15 4 20 4 20 9" />
                </svg></div
            ></a>
            <router-link
              v-else
              :class="
                `no-underline block mt-4 md:float-right sm:inline-block sm:mt-0 text-teal-lighter mr-4 ${getRefValue(
                  'navbarTextItemSize'
                )} ${
                  (item.route != PUBLIC_PATH
                  ? currentRoute.includes(item.route)
                  : item.route == currentRoute)
                    ? getRefValue('navbarTextItemHoverColor')
                    : getRefValue('navbarTextItemColor')
                }  hover:${getRefValue(
                  'navbarTextItemHoverColor'
                )} dark:text-black `
              "
              :to="item.route"
              >{{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ROUTES, openLink, PUBLIC_PATH } from './../config'
import { getCurrentInstance, onMounted, watchEffect } from 'vue'
import { ref } from 'vue'
import useSettings from './../store/settings'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const { getRefValue } = useSettings()
    const { ctx } = getCurrentInstance()
    const route = useRoute()
    const currentRoute = ref('')
    const scroll = (className) => {
      const el = ctx.$el.getElementsByClassName(className)[0]

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }

    let open = ref(false)

    const toggle = () => {
      open.value = !open.value
    }

    watchEffect(() => {
      currentRoute.value = route.path
      open.value = false
    })

    return {
      getRefValue,
      scroll,
      toggle,
      open,
      ROUTES,
      openLink,
      currentRoute,
      PUBLIC_PATH,
    }
  },
}
</script>

<style></style>
