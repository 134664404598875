import { reactive, toRefs } from "vue";
import api, { url } from "./../api/api";
import { ENTIDADE } from "./../config";

const state = reactive({
  PARAMS: {},
  loading: true,
});

export default function useSettings() {
  const fetchSettings = async () => {
    state.loading = true;
    const res = await api.get(`parametros/entidade/${ENTIDADE}`);
    state.PARAMS = res.data;
    state.loading = false;
  };

  const getRefValue = (ref) => {
    if (state.PARAMS == undefined || state.PARAMS == null) {
      return "";
    }
    const data = state.PARAMS.find((x) => x.ref == ref);
    if (data == undefined || data == null) return "";
    return data.value;
  };

  return {
    ...toRefs(state), // convert to refs when returning
    fetchSettings,
    getRefValue,
  };
}
