<template>
  <div class="container mx-auto pt-24  md:pt-28 bg-white dark:bg-black">
    <nav class="pr-3 pl-10 md:pl-2 rounded font-sans w-full mt-10 mb-5">
      <ol class="list-reset flex text-grey-dark">
        <li>
          <router-link
            :to="PUBLIC_PATH"
            class="no-underline text-blue dark:text-white font-bold"
            >Início</router-link
          >
        </li>
        <li><span class="mx-2">/</span></li>
        <router-link
          :to="PUBLIC_PATH + 'blog'"
          class="no-underline text-blue dark:text-white font-bold"
          >Notícias</router-link
        >
      </ol>
    </nav>
    <div class="flex  w-full">
      <div class="w-full  mx-auto px-10">
        <div v-if="loading">
          <ShimmerText />
        </div>
        <div v-if="!loading && data != null">
          <h1
            class="text-xl font-semibold mb-5 text-gray-700 dark:text-white md:text-xl"
          >
            {{ data.title }}
          </h1>

          <img
            v-if="data.cover"
            class=" w-full object-cover object-center mb-5 max-h-96"
            :src="data.cover"
            alt="blog"
          />

          <div class="w-full flex justify-between mb-5">
            <h1 class="text-md font-medium mb-5 text-gray-700 dark:text-white">
              Data de publicação: {{ dateToLocalDate(data.created_at) }}
            </h1>
            <h1
              class="text-md font-medium mb-5 text-gray-700 dark:text-white"
              v-if="data.updated_at"
            >
              Última edição: {{ dateToLocalDate(data.updated_at) }}
            </h1>
            <h1
              class="text-md font-medium mb-5 text-gray-700 dark:text-white"
              v-if="data.author"
            >
              Autor: {{ data.author }}
            </h1>
          </div>

          <div class="ql-snow text-black dark:text-white">
            <div v-html="data.content" class="ql-editor"></div>
          </div>
          <div class="justify-center">
            <img
              v-if="data.cover"
              class="object-cover inline-block object-center mb-5"
              :src="data.cover"
            />
          </div>

          <div class="w-full mt-10">
            <div class="container px-4 w-full mb-5">
              <!-- card wrapper -->
              <div class="wrapper bg-white dark:bg-black rounded-sm ">
                <h1
                  class="text-xl font-light  text-gray-700 dark:text-white md:text-xl"
                >
                  Compartilhar publicação
                </h1>
                <div class="card px-8 py-4 flex flex-wrap justify-center">
                  <a
                    target="_blank"
                    class="rounded px-3 py-2 m-1 justify-center hover:opacity-75 w-full md:w-1/6  border-b-4 border-l-2 shadow-lg bg-blue-600 dark:bg-white border-blue-700 flex flex-wrap"
                    :href="
                      'https://facebook.com/sharer/sharer.php?u=' +
                        encodeURI(currentPath)
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-brand-facebook"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#FFFFFF"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                      />
                    </svg>
                    <p class="text-white dark:text-black  ml-5 font-semibold">
                      Facebook
                    </p>
                  </a>
                  <a
                    target="_blank"
                    class="rounded px-3 py-2 m-1 justify-center hover:opacity-75  border-b-4 border-l-2 w-full md:w-1/6   shadow-lg bg-green-600 dark:bg-white border-green-700 flex flex-wrap"
                    :href="'whatsapp://send?text=' + encodeURI(currentPath)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-brand-whatsapp"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#ffffff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                      <path
                        d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1"
                      />
                    </svg>
                    <p class="text-white dark:text-black ml-5 font-semibold">
                      Whatsapp
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import API from './../../api/api.js'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import ShimmerText from './../../components/ShimmerText'

export default {
  components: { ShimmerText },
  setup() {
    const route = useRoute()

    const data = ref(null)
    const loading = ref(false)
    const error = ref(false)
    const form = ref({ key: '' })

    const currentPath = window.location.href + ''

    const fetchPost = async () => {
      try {
        loading.value = true
        const res = await API.get(
          `postagem/entidade/${ENTIDADE}/${route.params.id}`
        )
        data.value = res.data
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchPost()
    })

    const search = async () => {
      try {
        loading.value = true
        const res = await API.get(
          `postagem/search/entidade/${ENTIDADE}/${form.value.key}`
        )
        data.value = res.data
      } catch (e) {
        notification.open({
          message: 'Ops',
          description: 'Ocorreu um erro ao carregar os dados',
        })
      } finally {
        loading.value = false
      }
    }

    const cleanFilter = () => (form.value.key = '')

    const isValidKeySearch = ref(false)

    watchEffect(() => {
      form.value.key.length > 0
        ? (isValidKeySearch.value = true)
        : (isValidKeySearch.value = false)
    })

    const dateToLocalDate = (date) => {
      return moment(date).format('DD/MM/YYYY')
    }

    return {
      data,
      loading,
      error,
      form,
      isValidKeySearch,
      cleanFilter,
      search,
      dateToLocalDate,
      route,
      currentPath,
      PUBLIC_PATH,
    }
  },
}
</script>
