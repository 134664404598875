<template>
  <div class="mx-auto pt-24 md:pt-28 arquivos">
    <br />
    <p class="font-medium text-2xl text-black dark:text-white">Transparência</p>
    <div class="justify-center mt-10">
      <img
        :src="
          getRefValue('baseUrl') +
            '/assets/animations/animation_300_kl86pavo.gif'
        "
        class="inline-block w-56 h-56"
      />

      <h3
        class="text-justify mt-8 mb-8 px-10 md:px-64 text-lg font-normal leading-8 text-black dark:text-white"
      >
        A transparência é o melhor antídoto contra corrupção, dado que ela é
        mais um mecanismo indutor de que os gestores públicos ajam com
        responsabilidade.
        <br />
        <br />
        Uma gestão pública transparente permite à sociedade, com informações,
        colaborar no controle das ações de seus governantes, com intuito de
        checar se os recursos públicos estão sendo usados como deveriam.
      </h3>
    </div>

    <div
      v-if="loading"
      class="center-content justify-center mx-auto container w-full flex flex-wrap"
    >
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
      <ShimmerCard width="w-1/6" height="h-0" />
    </div>
    <p v-if="error"></p>
    <div class="flex flex-wrap pt-3 px-l0 md:px-28 pb-10 justify-center">
      <!-- <div v-for="(item, idx) in cardItems" :key="idx">
          <CardVue :item="item" />
        </div> -->
      <!-- @click="viewFiles(item)" -->
      <!--  -->

      <router-link
        v-for="(item, idx) in data"
        :key="idx"
        :class="
          `rounded bg-card-home cursor-pointer flex justify-center items-center w-52 h-52 p-4 m-4 hover:shadow-2xl transition duration-500 hover:scale-110 transform ${getRefValue(
            'cardPrimaryBgColor'
          )} hover:${getRefValue('cardPrimaryBgHoverColor')} dark:bg-white `
        "
        :to="PUBLIC_PATH + 'arquivos/' + item.id + '/' + item.titulo"
      >
        <div class="mx-auto">
          <div class="w-full">
            <img
              :src="getRefValue('baseUrl') + item.imagem"
              :class="`inline-block cursor-pointer w-20 h-20 dark:bg-black`"
            />
          </div>
          <h3
            class="text-center text-base break-word font-semibold align-middle inline-block text-white dark:text-black"
          >
            {{ item.titulo }}
          </h3>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import API from './../../api/api.js'
import { ENTIDADE, PUBLIC_PATH } from './../../config'
import useSettings from './../../store/settings'
import { Spin } from 'ant-design-vue'
import ShimmerCard from '@/components/ShimmerCard'

export default {
  components: {
    Spin,
    ShimmerCard,
  },
  setup() {
    const { ctx } = getCurrentInstance()

    const { getRefValue } = useSettings()

    const data = ref(null)
    const loading = ref(true)
    const error = ref(null)

    const fetchItems = async () => {
      loading.value = true
      const res = await API.get(`transparencia`)
      if (res.status != 200) {
        const error = new Error(res.status)
        error.value.message = 'Ocorreu um erro'
        throw error
      } else {
        data.value = res.data
      }
      loading.value = false
    }

    onMounted(() => {
      fetchItems()
    })

    const viewFiles = (item) => {
      ctx.$router.push({
        path: `/arquivos/${item.id_grupo}/${item.titulo}`,
      })
    }

    return {
      fetchItems,
      loading,
      data,
      error,
      viewFiles,
      getRefValue,
      PUBLIC_PATH,
    }
  },
}
</script>
<style scoped>
.img-left {
  width: 40vw;
  max-width: 250px;
  min-width: 200px;
}
</style>
